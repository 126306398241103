import React, { useEffect, useRef, useState } from "react";
import PatientBanner from "../assets/images/patientbgimg.jpg";
import Banner2 from "../assets/images/Banner.png"
import { GetListById, icons } from "./ShareComp";
import Header from "./Header";
import { NavLink } from "react-router-dom";
import { url } from "../services/Urls";
import OpenRaz from "../Razorpay/OpenRaz";
import { FaCircle } from "react-icons/fa";
import heart from "../assets/images/heartRate.jpeg";
import bloodPressure from "../assets/images/bloodpresure.jpeg";
import bmi from "../assets/images/bMI.jpeg";
import so2 from "../assets/images/spo2.jpeg";
import stress from "../assets/images/stresslvl.jpeg";
import { MdCurrencyRupee } from "react-icons/md";
import { UseFormValidations } from "../validations/UseFormValidation";
const Dashboard = () => {
  const [payement, setPayment] = useState("");
  const [update,setupdate]=useState([])
  const [latestVital, setlatestVital] = useState({});
  const [userRisk,setUserRisk]=useState({})
  const [allPlan, stAllplan] = useState([]);
  const [individual, setindividual] = useState([]);
  const [obj,setobj]=useState({})
 
  const list = [
    {
      name: "Diabetic",
      icon: icons?.diabetis,
      range: userRisk?.diabetes||0,
      level: "Low Risk",
      bg: "#FBE285",
    },
    {
      name: "Obesity Score",
      icon: icons?.obsety,
      range: userRisk?.obesity||0,
      level: "Low Risk",
      bg: "#FFDDC1",
    },
    {
      name: "Cardiac Score",
      icon: icons?.cardio,
      range: userRisk?.cardio||0,
      level: "Low Risk",
      bg: "#D0FBFF",
    },
  ];
  const listTwo = [
    {
      name: "Heart rate",
      icon: icons?.diabetis,
      range: latestVital?.pulseRate || "0",
      label: "bpm",
      level: "Low Risk",
      bg: "#E3F1F4",
      height: "400px",
      img:heart,
       height2:"150px",
      width:"200px",
      top:"30px"
    },
    {
      name: "Blood Pressure",
      icon: icons?.obsety,
      range: latestVital?.bloodPressure || "0/0",
      label: "mm Hg",
      level: "Low Risk",
      bg: "#FBE285",
      height: "350px",
      img:bloodPressure,
       height2:"150px",
      width:"200px",
      top:"10px"
    },
    {
      name: "BMI",
      icon: icons?.cardio,
      range: latestVital?.bmi || "0",
      level: "Low Risk",
      bg: "#F6F6F6",
      height: "250px",
      img:bmi,
       height2:"100px",
      width:"200px"
    },
    {
      name: "SPO2",
      icon: icons?.cardio,
      range: latestVital?.oxygenSaturation || "0%",
      level: "Low Risk",
      bg: "#FFDDC1",
      height: "350px",
      img:so2,
       height2:"150px",
      width:"200px",
      top:"10px"
    },
    {
      name: "Stress Level",
      icon: icons?.cardio,
      range: latestVital?.bmi || "0",
      level: "Low Risk",
      bg: "#D0E9BC",
      height: "400px",
      img:stress,
      height2:"150px",
      width:"200px",
      top:"30px"
    },
    { text: "Much More Track results and trends, effortlessly." },
  ];
  
  const userGet = async (userId) => {
    let res = await GetListById(url.userGetById, { id: userId });
    setobj(res)
    
  };
  const allPlans = async () => {
    let res = await GetListById(url.planPackage, { id: 0 });
    
    res?.map((v) => {
      v["title"] =
        v?.planName == "Standard"
          ? "For individuals"
          : v?.planName == "Elite"
          ? "For startups"
          : v?.planName == "Premimum"
          ? "For big companies"
          : "Any";
          v["planType"]="SubscriptionPlan"

    });
    let fil= res?.filter((v)=>(obj?.planName=="Premimum"||obj?.planName=="Elite")?v?.planName!="Standard":v)
    stAllplan(res);
  };
  const allIndividual = async () => {
    let res = await GetListById(url.individualPack, { id: 0 });
    res?.map(v=>{ v["planType"]="ScanPackage"})
    setindividual(res);
    
  };
const  LatestVitals=async()=>{
let res=await GetListById(url?.latestVitals,{id:sessionStorage.getItem("userId")});
setlatestVital(res)

}
const  LatestRisk=async()=>{
  let res=await GetListById(url?.userRisk,{id:sessionStorage.getItem("userId")});
  setUserRisk(res)
  
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
   
    allIndividual();
    LatestVitals()
    LatestRisk()
  }, []);
  console?.log(latestVital,"res")
  useEffect(() => {
    sessionStorage.getItem("userId")&& userGet(sessionStorage.getItem("userId"));

  }, [sessionStorage.getItem("userId"),update]);
  useEffect(()=>{
    allPlans()
  },[obj])
  return (
    <div className="">
      <Header  obj={obj} update={setupdate}/>
      {payement != "" && (
        <OpenRaz setIsModalOpen={setPayment} payement={payement} obj={obj} update={setupdate}/>
      )}
      
      
      <section id="banner" className="">
      <div  className="d-flex flex-wrap ">
        <img src={Banner2} className="img-fluid w-100 vh_60 padding_top" />
      </div>

      </section>

      <section id="vitals">
      <div  className="d-flex flex-wrap my-3 ">
       
        <div className="col-md-4 px-5">
          <h4>
            <span>Take A Selfie.</span>
            <div className="text-primary">
              See what your Vital Signs are doing.
            </div>
          </h4>
          <p>
            Vitals AI (VAI) measures your key health indicators by analysing a
            scan of your face - a selfie!
          </p>
        </div>

        <div className="d-flex flex-wrap   col-md-8  padding_left">
          {list?.map((v) => {
            return (
             <div className="px-2 col-md-4">
               <div
                className="col-md-12  my-2 "
                style={{ background: v?.bg, borderRadius: "20px" }}
              >
                <div class="d-flex justify-content-between p-3">
                  <div className="col-md-8 ">
                    <h5 class=" d-flex align-items-center gap-2">
                      <img
                        src={v?.icon}
                        className="bg-white"
                        style={{ borderRadius: "50%" }}
                      />{" "}
                      {v?.name}
                    </h5>
                    <h6 class=" text-body-secondary color2 px-4 py-2">
                      {v?.range}
                    </h6>
                    
                    <p
                      class="card-text btn border  risk_bg px-4"
                      style={{ borderRadius: "20px" }}
                    >
                      Low Risk
                    </p>
                  </div>
                  <div className="col-md-4 " >
                    {/* <progress value={"30"} max={"70"}></progress> */}
                    <img src={icons.risklevel} className="img-fluid" />
                  </div>

                  {/* <a href="#" class="card-link">Card link</a>
    <a href="#" class="card-link">Another link</a> */}
                </div>
              </div>
             </div>
            );
          })}
        </div>
        {/* second part */}
      </div>
      <div  className="d-flex flex-wrap justify-content-around gap-2  px_5">
        {listTwo?.map((v) => {
          return (
            <div
              className={v.text ? "col-md-5" : "col-md-2 "}
              style={{
                background: v?.bg,
                borderRadius: "20px",
                height: v.height,
              }}
            >
              {!v.text && (
                <div class="card-body">
                  <h5 class="card-title d-flex align-items-center gap-2">
                    <img
                      src={v?.icon}
                      className="bg-white"
                      style={{ borderRadius: "50%" }}
                    />{" "}
                    {v?.name}
                  </h5>
                  <h6 class=" mb-2 text-body-secondary color2 px-4 pt-4">
                    {v?.range}
                  </h6>
                  <p className="color2  px-4">{v.label}</p>
                  <p class=" ">
                   <img src={v.img} className="img-fluid text-center" style={{borderRadius:"20px",height:v.height2,width:v.width,marginTop:v?.top}}/>
                  </p>
                </div>
              )}
              <div className="text">{v?.text}</div>
            </div>
          );
        })}
      </div>
      </section>
      
      <section id="individual_packages">
      <div  className="d-flex flex-wrap   px-5 ">
        <h4 className="text-center my-4 px-4 color col-12">Buy Individual Packages</h4>
        {individual?.map((v) => {
          return (
            <div className=" col-md-4 my-2 px-2">
              <div
                class="d-flex flex-wrap col-md-12 col-lg-12 col-xl-12 borderRadius rounded border justify-content-around "
               
              >
                <div
                  class="col-md-6 d-flex flex-wrap p-3   bg-white"
                 
                >
                  <div className="col-md-3">
                    <img src={icons.roundcircle} className="img-fluid " />
                  </div>
                  <div className="col-md-6 px-2">
                    <div className="text2">For Individual</div>
                    <h5 class="card-title text3 color2 my-1 " 
                        style={{ borderRadius: "50px" }}>
                      {v?.scansCount}
                    </h5>
                    
                  </div>
                  <div className="col-md-12 py-2 text2">
                    Show social proof notifications to increase leads and sales.
                  </div>
                  <div className="col-md-12 ">
                    <span className="text3 color2 "><MdCurrencyRupee className=" color2"/>{v?.amount}</span>
                    <span className="text2 px-2">Active Plan</span>
                  </div>
                  <div className="col-md-12 w-100 py-2 ">
                    <button
                    onClick={() => setPayment(v)}
                      className="btn bg_btn text-white px-4"
                      style={{ borderRadius: "50px" }}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
                <div class="col-md-6 d-flex flex-wrap p-3 ">
                  <div class=" ">
                    <h5 class="color2">What’s included</h5>
                    <p class="card-text gap-2 d-flex text2">
                      <span>{icons.bluecircle2}</span>
                      <span>All analytics features</span>
                    </p>
                    <p class="card-text gap-2 d-flex text2">
                      <span>{icons.bluecircle2}</span>
                      <span>Up to 250,000 tracked visits</span>
                    </p>
                    <p class="card-text gap-2 d-flex text2">
                      <span>{icons.bluecircle2}</span>
                      <span>Normal support</span>
                    </p>
                    <p class="card-text gap-2 d-flex text2">
                      <span>{icons.bluecircle2}</span>
                      <span>Up to 3 team members</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </section>
      <section id="subscription_plans">
      <div  className="row mt-5 px_5">
        <h4 className="text-center color">Simple, transparent pricing</h4>
        <p className="text-center">
          Lorem ipsum dolor sit amet consectetur adipiscing elit dolor posuere
          vel venenatis eu sit massa volutpat.
        </p>

        <div class="d-flex flex-wrap justify-content-center gap-3 mb-2  ">
          {allPlan?.map((v, i) => {
            return (
              v?.planName != "expert" && (
                <div
                  className="col-md-3 border bg_white  mt-2  p-2"
                  style={{ height: "500px", borderRadius: "20px" }}
                >
                  <div class=" d-flex flex-wrap  col-auto ">
                    <div className="col-md-3">
                      <img
                        src={icons.roundcircle}
                        className="img-fluid border border-white bg-white"
                        style={{ borderRadius: "20px" }}
                      />
                    </div>
                    <div className="col-md-6">
                      <div>{v?.title}</div>
                      <h5 class="color2">{v?.planName}</h5>
                    </div>
                    <div className="col-md-12">
                      Show social proof notifications to increase leads and
                      sales.
                    </div>
                    
                  </div>
                  <div class="my-3 ">
                    <div className="d-flex flex-wrap gap-2 align-items-center border rounded">
                      <span class="color d-flex align-items-center px-3">
                       {v?.planName!="Standard"&& <MdCurrencyRupee className="pt-2"/>}
                        <span>{v?.planName=="Standard"?"Free": v?.amount}</span></span>
                      { <span className="">/Monthly</span>}
                      <span
                        className="bg_btn btn text-white border border-white"
                        style={{ borderRadius: "50px" }}
                      >
                        {v?.totalScans}
                      </span>

                    </div>

                    <div class=" my-3">
                      <h5 class="color2 my-3">What’s included</h5>
                      <p class="card-text gap-2 d-flex">
                        <span>{icons.bluecircle2}</span>
                        <span>All analytics features</span>
                      </p>
                      <p class="card-text gap-2 d-flex">
                        <span>{icons.bluecircle2}</span>
                        <span>Up to 250,000 tracked visits</span>
                      </p>
                      <p class="card-text gap-2 d-flex">
                        <span>{icons.bluecircle2}</span>
                        <span>Normal support</span>
                      </p>
                      <p class="card-text gap-2 d-flex">
                        <span>{icons.bluecircle2}</span>
                        <span>Up to 3 team members</span>
                      </p>
                    </div>
                  </div>
                  {v?.planName!="Standard"&&<div className="col-md-12 d-flex justify-content-center mt-5" >
                    <button
                    disabled={v?.planName==obj?.planName}
                      className="btn bg_btn text-white px_5 border border-white"
                      style={{ borderRadius: "30px" }}
                      onClick={() => setPayment(v)}
                    >
                      Choose Plan
                    </button>
                  </div>}
                </div>
              )
            );
          })}
        </div>
      </div>
      </section>

      <section id="footer">
      <div className="d-flex flex-wrap py-3  px-5">
        <div className="col-md-3">
          <h4 className="color">BluHealth</h4>
          <p className="text_footer">Get started noew try our product</p>
          <div className="col-md-10">
            <input
              className="form-control p-2 text_footer "
              placeholder="Please enter your E-mail here..."
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="color3">Support</div>
          <p className="text_footer">Help centre</p>
          <p className="text_footer">Account information</p>
          <p className="text_footer">About</p>
          <p className="text_footer">Contact us</p>
        </div>
        <div className="col-md-3">
          <div className="color3">Help and Solution</div>
          <p className="text_footer">Talk to support</p>
          <p className="text_footer">Support docs</p>
          <p className="text_footer">System status</p>
          <p className="text_footer">Covid responde</p>
        </div>
        <div className="col-md-3">
          <div className="color3">Product</div>
          <p className="text_footer">Update</p>
          <p className="text_footer">Security</p>
          <p className="text_footer">Beta test</p>
          <p className="text_footer">Pricing product</p>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-between px_5 my-2">
        <div className="color3">
          © 2024 LionOrbit Tech Solution PVT LTD. Copyright and rights reserved
        </div>
        <div className="color3">
          {" "}
          <NavLink to={"/policy"} className={"none"}>
            Terms and Condtions Privacy Policy
          </NavLink>
        </div>
      </div>
      </section>
      
      <div>
      <nav className="position-fixed " style={{right:"0",bottom:"0"}}>
        <div><a href="#banner" className="active"><FaCircle /> </a></div>
        <div><a href="#vitals"><FaCircle /> </a></div>
        <div><a href="#individual_packages" ><FaCircle /> </a></div>
        <div><a href="#subscription_plans"><FaCircle /> </a></div>
        <div><a href="#footer"><FaCircle /> </a></div>
    </nav>
      </div>
    </div>
  );
};

export default Dashboard;
